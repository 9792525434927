.pagar-boleto-container {
  padding: 1.5rem 0 5.625rem 8.75rem;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 5rem;

  color: var(--var(--text-primary));
}

.pagar-boleto-container .loader-div {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.step1-content {
  display: flex;
  flex-direction: column;
  gap: 3.5rem;
}

.step-buttons {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.step-buttons > a:hover {
  color: var(--pay-ticket-cancel-button-color);
}

.step-button {
  padding: 0.375rem 1rem;
  min-width: 11.25rem;

  background: var(--pay-ticket-cancel-button-background);
  border: 2px solid var(--pay-ticket-cancel-button-border);
  border-radius: 4px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  text-decoration: none;
  color: var(--pay-ticket-cancel-button-color);
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.step-button-red {
  background: var(--pay-ticket-confirm-button-background);
  border-color: var(--pay-ticket-confirm-button-border);
  color: var(--pay-ticket-confirm-button-color);
}

.step2-input-container {
  max-width: 26.28rem;
}

.step2-content-infos,
.step3-content-infos {
  display: flex;
  flex-direction: column;
  gap: 1.625rem;

  margin-bottom: 1.625rem;
}

.step2-content-info,
.step3-content-info {
  display: flex;
  justify-content: space-between;
}

.step2-buttons,
.step3-buttons {
  margin-top: 2.1875rem;
}

.info-item {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.info-item span {
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: 0.5px;
}

.input-s {
  width: 22.25rem;
}

.disabled-link {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}