.transferencia-pix{
    color: var(--text-primary);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.transferencia-pix-container{
    height: 100%;
}

.transferencia-pix .transferencia-pix-container .header{
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.transferencia-pix .transferencia-pix-container .header a {
    text-decoration: none;
}

.transferencia-pix .header .col-xl-12 p{
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.5px;
}

.transferencia-pix .header .col-xl-12 a{
    color: var(--header-link-color);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0.5px;
}

.transferencia-pix .header .col-xl-12 label{
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0.5px;
}

.transferencia-pix label.error-message{
    color: var(--auxiliary-color);
    font-family: Poppins;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-top: 0.5rem;

    display: flex;
    align-items: center;
    gap: 0.25rem;
}

.transferencia-pix .valid{
    display: none !important;
}

.transferencia-pix .invalid{
    display: flex !important;
}

.transferencia-pix .invalid-input{
    border-radius: 4px;
    border-bottom: 2px solid var(--auxiliary-color) !important;
    background: #F8D7DA !important;
}

.transferencia-pix .invalid-input *{
    color: var(--secondary-color) !important;
}

.transferencia-pix .invalid-input input::placeholder{
    color: var(--secondary-color) !important;
}

.transferencia-pix .body{
    margin-top: 30px;
    display: flex;

}

.transferencia-pix .body h1{
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0.5px;
}

.transferencia-pix .body h2{
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 11px;
    letter-spacing: 1px;
}

.transferencia-pix .body .btn-contatos{
  background-color: var(--pix-transfer-contact-button-background);
  border: 2px solid var(--pix-transfer-contact-button-border);
  color: var(--pix-transfer-contact-button-color);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  font-weight: 600;
}

.transferencia-pix .body .btn-contatos-disabled{
  background-color: var(--disabled-color);
  border: 2px solid var(--disabled-color);
  color: #FFFFFF;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  font-weight: 600;
}

.transferencia-pix .body .btn-contatos svg{
    color: var(--gray-var(--text-primary), var(--text-primary));
}

.transferencia-pix .body .recent-icons{
    width: 56px;
    height: 56px;
    flex-shrink: 0;
    background-color: var(--recent-icons-background);
    color: var(--recent-icons-color);
    border: var(--recent-icons-border);
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.transferencia-pix .body .recent-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 15px;
    margin-top: 15px;
}

.transferencia-pix .body .recent-card span{
    color: var(--text-primary);
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 11px;
}

.transferencia-pix .body .recent-card p{
    color: var(--text-primary);
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px;
    margin: 0;
    cursor: pointer;
}

.transferencia-pix .body .recent-content{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    gap: 5px;
}

.transferencia-pix .body .input-area{
    border-radius: 4px;
    border: 1px solid var(--text-primary);
    background: #FFFFFF;
    display: flex;
    height: 38px;
    align-items: center;
}

.transferencia-pix .body .input-area .input-area-icon{
    height: 100%;
    display: flex;
    align-items: center;
}

.transferencia-pix .body .input-area label{
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.transferencia-pix .body .input-area svg{
    margin-left: 10px;
    color: #000;
}

.transferencia-pix .body .input-area input{
    background: transparent;
    border: none;
    height: 36px;
    width: 100%;
    align-items: center;
    border-radius: 4px;
    padding-left: 10px;
}

.transferencia-pix .body .input-area input::placeholder{
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.transferencia-pix .body .input-area input:focus{
    box-shadow: 0 0 0 0;
    outline: 0;
}


.transferencia-pix .body button {
    display: flex;
    width: 180px;
    height: 38px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid var(--pix-transfer-confirm-button-border);
    color: var(--pix-transfer-confirm-button-color);
    background: var(--pix-transfer-confirm-button-background);
    font-weight: 600;
}

.transferencia-pix .footer {
    margin-top: 40px;
}

.transferencia-pix .footer h2 {
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 11px;
    letter-spacing: 1px;
}

.transferencia-pix .footer .historico-area{
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: black transparent;
    height: 450px;
    margin-top: 25px;
}

.transferencia-pix .footer .historico-area::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.transferencia-pix .footer .historico-area::-webkit-scrollbar-track {
    background: transparent;
    padding: 3px;
}

.transferencia-pix .footer .historico-area::-webkit-scrollbar-thumb {
    background-color: #cecece;
    border-radius: 10px;
}

.pix-modal{
    position: relative;
    z-index: 9999;
    width: 750px;
    height: 500px;
    flex-shrink: 0;
    background: var(--bg-modal-color);
    border-radius: 12px;
    border: 1px solid var(--modal-border-color);
    margin-top: 100px;
    margin-left: 340px;
}

.overlay-pix-modal{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99999999;
    background: rgba(0, 0, 0, 0.40);
}

.pix-modal .pix-modal-header {
    padding: 41px 33px;
}

.pix-modal .pix-modal-header h1{
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px; /* 59.091% */
    letter-spacing: 0.5px;
}

.pix-modal .pix-modal-header svg{
    margin-bottom: 20px;
}

.pix-modal .pix-modal-body {
    padding: 0px 33px;
}

.pix-modal .pix-modal-body label{
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0.5px;
}

.pix-modal .pix-modal-body p{
    color: var(--text-primary);
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0.5px;
    width: 80%;
}

.pix-modal .pix-modal-body .errorMessage{
    color: #F2383A;
    margin-top: 1rem;
    margin-bottom: 0;
}

.pix-modal .pix-modal-body .input-area-icon{
    display: flex;
    justify-content: center;
}

.pix-modal .pix-modal-body .input-area{
    border-radius: 4px;
    display: flex;
    height: 38px;
    align-items: center;
    border: 1px solid var(--pix-transfer-modal-input-border);
    background: var(--pix-transfer-modal-input-background);
    color: var(--pix-transfer-modal-input-color);
}

.pix-modal .pix-modal-body .input-area input{
    background: transparent;
    border: none;
    height: 36px;
    width: 100%;
    align-items: center;
    border-radius: 4px;
    padding-left: 10px;
}

.pix-modal .pix-modal-body .input-area input:focus{
  box-shadow: 0 0 0 0;
  outline: 0;
}

.pix-modal .pix-modal-footer{
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 50px 33px 49px 33px;
}

.pix-modal .pix-modal-footer a,
.pix-modal .pix-modal-footer button{
    text-decoration: none;
    display: flex;
    width: 180px;
    height: 38px;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.pix-modal .pix-modal-footer .confirm-button{
    border-radius: 4px;
    border: 1px solid var(--pix-transfer-modal-confirm-button-border);
    background: var(--pix-transfer-modal-confirm-button-background);
    color: var(--pix-transfer-modal-confirm-button-color);
    font-weight: 600;
}

.pix-modal .pix-modal-footer .cancel-button{
    border-radius: 4px;
    border: 2px solid var(--pix-transfer-modal-cancel-button-border);
    background: var(--pix-transfer-modal-cancel-button-background);
    color: var(--pix-transfer-modal-cancel-button-color);
    font-weight: 600;
}

.pix-modal .pix-modal-footer .share-button{
    display: flex;
    flex-direction: row;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    height: 38px;
    width: 340px;
    padding: 8px 16px;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid var(--share-receipt-button-border);
    background:  var(--share-receipt-button-background);
    margin-top: -20px;
    color: var(--share-receipt-button-color);
}

.close-button{
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 10px;
    margin-right: 10px;
}
